var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[(!_vm.itemsPaginated.total)?_c('v-banner',{scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.refresh()}}},[_vm._v("\n                        Refresh\n                    ")])]},proxy:true}],null,false,3687870033)},[_vm._v("\n                No Items Found\n                ")]):_vm._e(),_vm._v(" "),_c('List',{attrs:{"archive":_vm.archive,"current-route":_vm.currentRoute,"items":_vm.itemsPaginated.data},scopedSlots:_vm._u([{key:"top-right",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.copyLink(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-link")])],1)]}}],null,true)},[_vm._v("\n                        Copy Link\n                    ")])]}},{key:"body",fn:function(ref){
var item = ref.item;
return [_c('HighestBid',{staticClass:"pb-2",attrs:{"bidding-floor":item.highest_bid_amount}}),_vm._v(" "),(item.is_over_bidding_end_date || item.status_booleans.isTemporarilyClosed)?_c('div',{staticClass:"mb-6 item-countdown"},[(item.status_booleans.isTemporarilyClosed)?_c('h5',[_vm._v("\n                            "+_vm._s(!item.is_active ? "Auction is temporarily closed." : ("Auction set to open on " + _vm.biddingStartDate(item)))+"\n                        ")]):_c('h5',[(!item.status_booleans.isEndedWithoutSell)?_c('span',[_vm._v(_vm._s(item.bid_count)+" Bids")]):_c('span',[_vm._v("Auction ended without winner.")]),_vm._v(" "),(item.bid_count > 0)?_c('span',[_vm._v("\n                                •\n                                "),_c('strong',[_vm._v("Winner")]),_vm._v(": "+_vm._s(item.highest_bid.user.name)+"\n                                •\n                            ")]):_vm._e(),_vm._v(" "),(item.current_status.value === 'wonUnpaid')?_c('span',[_vm._v("\n                                Unpaid\n                            ")]):(item.current_status.value === 'wonPaid')?_c('span',[_vm._v("\n                                Paid / Not Shipped\n                            ")]):(item.current_status.value === 'wonShipped')?_c('span',[_vm._v("\n                                Paid / Shipped\n                            ")]):_vm._e()])]):_c('ItemCountdown',{staticClass:"mb-2",attrs:{"bid-count":item.bid_count,"bidding-ended-at":item.bidding_ended_at,"item":item}})]}},{key:"left",fn:function(ref){
var item = ref.item;
return [((item.status_booleans.isTemporarilyClosed || item.status_booleans.isOpenToBid || item.status_booleans.isEndedWithoutSell) && !item.is_archived)?_c('Link',{staticClass:"hover:tw-no-underline",attrs:{"href":_vm.editPage(item)}},[_c('v-btn',{attrs:{"small":"","text":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v("\n                            Edit\n                        ")],1)],1):_vm._e(),_vm._v(" "),_c('DeleteHighestBidAction',{attrs:{"item":item,"item-is-ended-without-sell":item.status_booleans.isEndedWithoutSell,"item-is-open-to-bid":item.status_booleans.isOpenToBid,"permissions":_vm.permissions}}),_vm._v(" "),_c('SendPaymentReminderAction',{attrs:{"item":item,"errors":_vm.errors}}),_vm._v(" "),_c('MarkAsPaid',{attrs:{"errors":_vm.errors,"item":item,"permissions":_vm.permissions,"states":_vm.states}}),_vm._v(" "),(item.status_booleans.isWonAndPaid || item.status_booleans.isWonAndShipped)?_c('Link',{staticClass:"hover:tw-no-underline",attrs:{"href":_vm.receiptPage(item)}},[_c('v-btn',{attrs:{"small":"","text":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-receipt")]),_vm._v("\n                            Show Receipt\n                        ")],1)],1):_vm._e(),_vm._v(" "),(item.status_booleans.isWonAndPaid || item.status_booleans.isWonAndShipped)?_c('Link',{staticClass:"hover:tw-no-underline",attrs:{"href":_vm.accessItemShipmentForm(item)}},[_c('v-btn',{attrs:{"small":"","text":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-ferry")]),_vm._v("\n                            Shipping Details\n                        ")],1)],1):_vm._e(),_vm._v(" "),_c('ArchiveAction',{attrs:{"board":_vm.board,"is-not-archive-page":!_vm.archive,"item":item,"permissions":_vm.permissions}})]}}])})],1)],1),_vm._v(" "),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',[_c('Pagination',{attrs:{"current-route":_vm.currentRoute,"data":_vm.itemsPaginated,"route-name":_vm.routeName}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }