"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var moment_1 = __importStar(require("moment"));
var vue_property_decorator_1 = require("vue-property-decorator");
var ItemCountdown = /** @class */ (function (_super) {
    __extends(ItemCountdown, _super);
    function ItemCountdown() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ItemCountdown.prototype.moment = function () {
        return (0, moment_1.default)();
    };
    Object.defineProperty(ItemCountdown.prototype, "timeFromNow", {
        get: function () {
            var subtraction = -(0, moment_1.default)((0, moment_1.now)()).diff(this.biddingEndedAt, 'millisecond');
            return subtraction > 0 ? subtraction : 0;
        },
        enumerable: false,
        configurable: true
    });
    ItemCountdown.prototype.formatDate = function (date, format) {
        if (format === void 0) { format = null; }
        return (0, moment_1.default)(date).format(format);
    };
    __decorate([
        (0, vue_property_decorator_1.Prop)({ type: String, required: true })
    ], ItemCountdown.prototype, "biddingEndedAt", void 0);
    __decorate([
        (0, vue_property_decorator_1.Prop)({ type: Number, required: true })
    ], ItemCountdown.prototype, "bidCount", void 0);
    ItemCountdown = __decorate([
        vue_property_decorator_1.Component
    ], ItemCountdown);
    return ItemCountdown;
}(vue_property_decorator_1.Vue));
exports.default = ItemCountdown;
