var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('InertiaHead',{attrs:{"title":"Emails (Admin)"}}),_vm._v(" "),_c('Main',[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.notifications},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                            "+_vm._s(item.name)+"\n\n                            "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle")])]}}],null,true)},[_vm._v("\n                                "+_vm._s(item.description)+"\n                            ")])]}},{key:"item.route",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"hover:tw-no-underline",attrs:{"href":item.route}},[_c('v-icon',[_vm._v("mdi-arrow-right")])],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }