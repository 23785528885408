"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    props: {
        noItems: { type: Boolean, required: true }
    },
    methods: {
        refreshPage: function () {
            location.reload();
        }
    }
};
