var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v("Admins")]),_vm._v(" "),_c('v-card-text',[_c('BoardEditAdminsTableAddUser',{attrs:{"board":_vm.board,"current-route":_vm.currentRoute,"errors":_vm.errors,"flash":_vm.flash}}),_vm._v(" "),_c('v-data-table',{staticClass:"mt-3",attrs:{"headers":_vm.headers,"items":_vm.users.data,"disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.role",fn:function(ref){
var item = ref.item;
return [(item.is_authenticated_user)?_c('span',[_vm._v(_vm._s(item.main_role.display_name))]):_c('BoardEditAdminsTableChangeRole',{attrs:{"board":_vm.board,"current-route":_vm.currentRoute,"flash":_vm.flash,"roles":_vm.roles,"user":item}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(!item.is_authenticated_user)?_c('div',[(!item.has_bypass_board_admin_permission)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.openRemovalDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_vm._v("\n                        Revoke "+_vm._s(item.name.endsWith('s') ? ((item.name) + "'") : ((item.name) + "'s"))+" admin privileges\n                        over\n                        "+_vm._s(_vm.board.name)+".\n                    ")]):_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-btn',{attrs:{"disabled":"","icon":""}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)]}}],null,true)},[_vm._v("\n                            User must be given the "+_vm._s(_vm.noBypassBoardAdminPermissionDefaultRole.display_name)+" role\n                            before they can have their admin privileges revoked.\n                        ")])],1)],1):_vm._e()]}},{key:"footer",fn:function(){return [_c('Pagination',{attrs:{"current-route":_vm.currentRoute,"data":_vm.users,"route-name":_vm.currentRoute.name}})]},proxy:true}])})],1),_vm._v(" "),_c('BoardEditAdminsTableRemovalDialog',{attrs:{"board":_vm.board,"flash":_vm.flash},model:{value:(_vm.userToRemove),callback:function ($$v) {_vm.userToRemove=$$v},expression:"userToRemove"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }