<template>
    <h2 class="tw-text-center mb-3">
        Highest Bid:
        {{ bidCount ? "$" + highestBidAmountDollarFormat : "N/A" }}
    </h2>
</template>
<script>
import currency from "currency.js";

export default {
    props: {
        bidCount: {type: Number, required: false},
        highestBidAmount: {type: Number, required: true}
    },
    computed: {
        highestBidAmountDollarFormat() {
            return currency(this.highestBidAmount);
        }
    }
}
</script>
