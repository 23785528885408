<script>
const props = {
    breadcrumbs: {required: true, type: Array},
    hide: {required: true, type: Boolean}
};

const component = {
    props
}

export default component;
</script>


<template>
    <v-breadcrumbs class="d-print-none" :items="breadcrumbs" divider="/" v-if="!hide" :key="JSON.stringify(breadcrumbs)">
        <template #item="{ item }">
            <v-breadcrumbs-item
                :disabled="!item.hasOwnProperty('url') || !item.url"
            >
                <Link :href="item.url">
                    <span :class="{ 'tw-text-gray-400': !item.hasOwnProperty('url') || !item.url }"
                          v-html="item.title"></span>
                </Link>
            </v-breadcrumbs-item>
        </template>
    </v-breadcrumbs>
</template>

<style lang="scss" scoped>
    @import "../../../../sass/app.scss";

    @media only screen and (max-width: 600px) {
        .v-breadcrumbs {
            display: contents;
        }
    }

</style>
