<template>
    <VueFileAgent
        ref="vueFileAgent"
        :accept="'image/*'"
        :deletable="true"
        :editable="true"
        :errorText="{
      type: 'Invalid file type. Only images allowed.',
      size: 'Files should not exceed 10MB in size',
    }"
        :helpText="'Choose images'"
        :maxFiles="14"
        :maxSize="'10MB'"
        :meta="true"
        :multiple="!single"
        :theme="'list'"
        :uploadHeaders="uploadHeaders"
        :value="value"
        @beforedelete="removeFile($event)"
        @input="$emit('input', $event)"
    ></VueFileAgent>
</template>
<script>
export default {
    props: {
        value: [Array, Object],
        single: {default: false, type: Boolean}
    },
    data: function () {
        return {
            uploadHeaders: {"X-Test-Header": "vue-file-agent"},
        };
    },
    methods: {
        removeFile(file) {
            if (Array.isArray(this.value)) {
                const newArray = this.value.filter((x) => x.file.name !== file.file.name);
                
                this.$emit("input", newArray);
            } else {
                this.$emit("input", null);
            }
        },
    },
};
</script>
