var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('InertiaHead',{attrs:{"title":"Boards (Admin)"}}),_vm._v(" "),_c('Main',[_c('v-row',[_c('v-col',{staticClass:"text-end"},[_c('Link',{staticClass:"hover:tw-no-underline",attrs:{"href":_vm.boardCreateRoute}},[(_vm.permissions.create_boards)?_c('v-btn',{attrs:{"color":"primary","small":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v("\n                        Add New Board\n                    ")],1):_vm._e()],1)],1)],1),_vm._v(" "),_c('v-data-table',{staticClass:"mt-3",attrs:{"headers":_vm.headers,"items":_vm.boardsPaginated.data,"show-select":false,"hide-default-footer":""},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('Pagination',{attrs:{"current-route":_vm.currentRoute,"data":_vm.boardsPaginated,"route-name":_vm.currentRoute.name}})]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.name)}})]}},{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                "+_vm._s(item.is_active ? "Yes" : "No")+"\n            ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('Link',{staticClass:"hover:tw-no-underline",attrs:{"href":_vm.boardEditRoute(item)}},[(_vm.permissions.edit_boards)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e()],1)]}}],null,true)},[_vm._v("\n                    Edit Board\n                ")]),_vm._v(" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('Link',{staticClass:"hover:tw-no-underline",attrs:{"href":_vm.adminItemIndexRoute(item)}},[_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-right-bold-box-outline")])],1)],1)]}}],null,true)},[_vm._v("\n                    View Items\n                ")]),_vm._v(" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.copyLink(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-link")])],1)]}}],null,true)},[_vm._v("\n                    Copy Link\n                ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }