import _ from "lodash";
import Vue from "vue";
import _route from "@/Modules/Misc/route.ts";

/**
 * Returns a formatted link with all arguments as query parameters.
 *
 * @param currentParams
 * @param paramKey
 * @param paramValue
 * @param routeString
 * @param paramsToExclude
 * @returns {*}
 */
export default function writeToQuery(
    currentParams,
    paramKey,
    paramValue,
    routeString,
    paramsToExclude = null
) {
    let params = _.cloneDeep(currentParams);

    if (paramsToExclude) {
        paramsToExclude.forEach(function (q) {
            Vue.delete(params, q);
        });
    }

    params = typeof params == "object" ? params : {};

    Vue.set(params, paramKey, paramValue);

    const assignedParams = Object.assign({}, params);

    return _route(routeString, assignedParams);
}
