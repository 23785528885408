<template>
    <Lingallery
        :addons="{ enableLargeView: false }"
        :height="200"
        :iid.sync="currentId"
        :items="computedImages"
        :width="400"
        responsive
    />
</template>

<script>
import Lingallery from 'lingallery';

export default {
    data() {
        return {}
    },
    components: {
        Lingallery
    },
    props: {
        images: {
            type: Array,
            required: true
        }
    },
    computed: {
        computedImages() {
            if (this.images) {
                return this.images.map((x) => {
                    return {
                        src: x['full_path'],
                        thumbnail: x['full_path'],
                        id: x.id,
                    };
                });
            } else {
                return [];
            }
        }
    },
    methods: {
        currentId() {
            return this.images.length ? this.images[0].id : null;
        }
    }
}
</script>
