<template>
    <div>
        <InertiaHead title="Emails (Admin)"></InertiaHead>

        <Main>
            <v-container>
                <v-row>
                    <v-col>
                        <v-data-table :headers="headers" :items="notifications">
                            <template #item.name="{item}">
                                {{ item.name }}

                                <v-tooltip top>
                                    <template #activator="{on, attrs}">
                                        <v-icon v-on="on" v-bind="attrs">mdi-help-circle</v-icon>
                                    </template>
                                    {{ item.description }}
                                </v-tooltip>
                            </template>

                            <template #item.route="{item}">
                                <a class="hover:tw-no-underline" :href="item.route">
                                    <v-icon>mdi-arrow-right</v-icon>
                                </a>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-container>
        </Main>
    </div>
</template>

<script>
export default {
    name: "Index",
    props: {
        notifications: {type: Array, required: true},
    },
    computed: {
        headers() {
            return [
                {
                    text: "Name",
                    align: "start",
                    value: "name",
                },
                {
                    text: "Link",
                    value: "route",
                },
            ];
        }
    }
}
</script>

<style scoped>

</style>
