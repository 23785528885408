var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseForm',{ref:"form",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('v-card',[(!_vm.isNotBidOn)?_c('v-card-title',[_c('small',{staticClass:"grey--text tw-italic"},[_vm._v("You may only edit images and description once an item\n                    has been bid on.")])]):_vm._e(),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('ImageUploader',{model:{value:(_vm.form.images),callback:function ($$v) {_vm.$set(_vm.form, "images", $$v)},expression:"form.images"}}),_vm._v(" "),(
                        _vm.item &&
                        _vm.form.currentImages &&
                        _vm.form.currentImages.length
                    )?_c('v-expansion-panels',{staticClass:"my-4"},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("\n                                    Images:\n                                    "+_vm._s(_vm.form.currentImages.length)+"\n                                ")]),_vm._v(" "),_c('v-expansion-panel-content',[_c('draggable',{attrs:{"list":_vm.form.currentImages,"handle":".handle","tag":"v-container"}},_vm._l((_vm.form.currentImages),function(image,index){return _c('v-row',{key:index},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"handle",staticStyle:{"cursor":"move"},attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-drag-horizontal\n                                                    ")])],1)],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"3"}},[_c('v-card',{class:{
                                                'tw-opacity-50':
                                                    image.to_delete,
                                            }},[_c('v-card-title',[_c('v-spacer'),_vm._v(" "),(
                                                        image.to_delete ===
                                                        false
                                                    )?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.toggleToDelete(
                                                            image
                                                        )}}},[_c('v-icon',[_vm._v("mdi-eye-off\n                                                            ")])],1):_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.toggleToDelete(
                                                            image
                                                        )}}},[_c('v-icon',[_vm._v("mdi-eye\n                                                            ")])],1)],1),_vm._v(" "),_c('v-card-text',[_c('v-img',{attrs:{"src":image.full_path,"width":"200px"}})],1)],1)],1)],1)}),1)],1)],1)],1):_vm._e(),_vm._v(" "),(_vm.isNotBidOn)?_c('v-text-field',{staticClass:"mt-2",attrs:{"error-messages":_vm.$page.props.errors.name,"label":"Name","required":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}):_vm._e(),_vm._v(" "),(_vm.isNotBidOn)?_c('CurrencyInput',{attrs:{"label":"Item Value","required":"","error-messages":_vm.$page.props.errors.itemValue},model:{value:(_vm.form.itemValue),callback:function ($$v) {_vm.$set(_vm.form, "itemValue", $$v)},expression:"form.itemValue"}}):_vm._e(),_vm._v(" "),(_vm.isNotBidOn)?_c('CurrencyInput',{attrs:{"label":"Initial Price","required":"","error-messages":_vm.$page.props.errors.initialPrice},model:{value:(_vm.form.initialPrice),callback:function ($$v) {_vm.$set(_vm.form, "initialPrice", $$v)},expression:"form.initialPrice"}}):_vm._e(),_vm._v(" "),(_vm.permissions.change_bank && _vm.isNotBidOn)?_c('BankSelect',{attrs:{"cached-banks":_vm.cachedBanks},model:{value:(_vm.form.bank),callback:function ($$v) {_vm.$set(_vm.form, "bank", $$v)},expression:"form.bank"}}):_vm._e(),_vm._v(" "),(
                        _vm.permissions.change_board && _vm.isNotBidOn
                    )?_c('v-select',{attrs:{"items":_vm.boards,"item-text":"name","item-value":"id","label":"Board","prepend-icon":"mdi-map"},model:{value:(_vm.form.board),callback:function ($$v) {_vm.$set(_vm.form, "board", $$v)},expression:"form.board"}}):_vm._e(),_vm._v(" "),_c('Editor',{attrs:{"label":"Description"},model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}}),_vm._v(" "),(_vm.isNotBidOn)?_c('v-switch',{attrs:{"label":"Auction Open"},model:{value:(_vm.form.isOpenToBid),callback:function ($$v) {_vm.$set(_vm.form, "isOpenToBid", $$v)},expression:"form.isOpenToBid"}}):_vm._e(),_vm._v(" "),(_vm.form.isOpenToBid && _vm.isNotBidOn)?_c('v-switch',{attrs:{"label":"Set Start Time"},model:{value:(_vm.form.changeStartTime),callback:function ($$v) {_vm.$set(_vm.form, "changeStartTime", $$v)},expression:"form.changeStartTime"}}):_vm._e(),_vm._v(" "),(_vm.form.isOpenToBid)?_c('v-row',[(_vm.form.changeStartTime)?_c('v-col',[_c('date-picker',{attrs:{"label":"Auction Start Time","error-messages":_vm.$page.props.errors.biddingStartedAt,"max-date":_vm.form.biddingEndedAt},model:{value:(_vm.form.biddingStartedAt),callback:function ($$v) {_vm.$set(_vm.form, "biddingStartedAt", $$v)},expression:"form.biddingStartedAt"}})],1):_vm._e(),_vm._v(" "),_c('v-col',[_c('date-picker',{attrs:{"label":"Auction End Time","error-messages":_vm.$page.props.errors.biddingEndedAt,"min-date":_vm.form.changeStartTime ? _vm.form.biddingStartedAt : undefined},model:{value:(_vm.form.biddingEndedAt),callback:function ($$v) {_vm.$set(_vm.form, "biddingEndedAt", $$v)},expression:"form.biddingEndedAt"}})],1)],1):_vm._e()],1)],1)],1),_vm._v(" "),_c('v-card-actions',[_vm._t("left"),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","type":"submit"}},[_vm._v(" Submit")])],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }