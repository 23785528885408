var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-4"},[_c('v-card-title',[_c('v-spacer'),_vm._v(" "),(!_vm.showSelect && _vm.items.length > 1)?_c('v-btn',{attrs:{"icon":"","outlined":""},on:{"click":function($event){_vm.showSelect = true}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e(),_vm._v(" "),(_vm.showSelect)?_c('v-btn',{attrs:{"icon":"","outlined":""},on:{"click":function($event){_vm.showSelect = false}}},[_c('v-icon',[_vm._v("mdi-content-save")])],1):_vm._e()],1),_vm._v(" "),_c('v-card-text',{staticClass:"pb-0 px-0"},[_c('v-data-table',{attrs:{"mobile-breakpoint":"0","headers":_vm.headers,"items":_vm.showSelect ? _vm.items : _vm.value,"show-select":_vm.showSelect,"value":_vm.value,"disable-pagination":"","hide-default-footer":""},on:{"input":function($event){return _vm.$emit('input', $event)}},scopedSlots:_vm._u([{key:"item.truncated_name",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"hover:tw-text-secondary hover:tw-underline",attrs:{"target":"_blank","href":_vm.itemShowLink(item)},domProps:{"innerHTML":_vm._s(item.truncated_name)}},'a',attrs,false),on))]}}],null,true)},[_vm._v("\n                    "+_vm._s(item.name)+"\n                ")])]}},{key:"item.item_value",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                "+_vm._s(_vm.formatCurrency(item.item_value))+"\n            ")]}},{key:"item.initial_price",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                "+_vm._s(_vm.formatCurrency(item.initial_price))+"\n            ")]}},{key:"item.highest_bid_amount",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                "+_vm._s(_vm.formatCurrency(item.highest_bid_amount))+"\n            ")]}}])})],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-card-title',[_c('v-spacer'),_vm._v(" "),_c('span',{staticClass:"tw-text-base"},[_vm._v("\n            Total Due: \n            "),_c('span',{staticClass:"tw-font-bold"},[_vm._v(_vm._s(_vm.formatCurrency(_vm.totalCost)))])])],1),_vm._v(" "),(_vm.$page.props.charges && _vm.$page.props.charges.length > 1)?_c('v-row',[_c('v-spacer'),_vm._v(" "),_c('v-col',{attrs:{"cols":"5"}},[_c('v-data-table',{attrs:{"mobile-breakpoint":"0","headers":_vm.chargeHeaders,"items":_vm.$page.props.charges,"disable-pagination":"","disable-sort":"","hide-default-footer":""}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }