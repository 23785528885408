var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item-countdown"},[_c('countdown',{key:_vm.biddingEndedAt,attrs:{"time":_vm.timeFromNow},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var days = ref.days;
var hours = ref.hours;
var minutes = ref.minutes;
var seconds = ref.seconds;
return [(_vm.timeFromNow > 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v("\n                "+_vm._s(_vm.bidCount)+" "+_vm._s(_vm.bidCount > 1 || _vm.bidCount === 0 ? 'Bids' : 'Bid')+"\n                •\n                "+_vm._s(days >= 1
                            ? (days + "d " + hours + "h")
                            : (hours + "h " + minutes + "m " + seconds + "s"))+"\n                ")])]}}],null,true)},[_vm._v("\n            "+_vm._s(_vm.formatDate(
                    _vm.biddingEndedAt,
                    "MMMM D, YYYY h:mm a"
                ))+"\n        ")]):_c('h6',{staticClass:"item-countdown"},[_vm._v("Bidding Closed!")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }