<template>
    <form @submit.prevent="handleSubmit">
        <slot name="default"></slot>
    </form>
</template>
<script>
export default {
    props: {
        form: { required: true, type: Object },
        dontValidateIsDirty: { type: Boolean, default: false },
    },
    methods: {
        handleSubmit() {
            this.$emit('submit', this.form);
        },
        preventLeave(event) {
            if (!this.form.isDirty) return;
            event.preventDefault();
            // Chrome requires returnValue to be set.
            event.returnValue = "";
        },
    },
    beforeDestroy() {
        window.removeEventListener("beforeunload", this.preventLeave);
    },
    beforeMount() {
        window.addEventListener("beforeunload", this.preventLeave);
    },
};
</script>
