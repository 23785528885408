"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function toastNotification(toast, message, level, props) {
    if (props === void 0) { props = {
        timeout: 10000,
        hideProgressBar: false,
        position: "top-right"
    }; }
    if (level == "success") {
        toast.success(message, props);
    }
    else if (level == "error") {
        toast.error(message, props);
    }
    else {
        toast.info(message, props);
    }
}
exports.default = toastNotification;
