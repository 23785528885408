"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CHANGE_BOARD_ADMIN_ROLE_ACTION = exports.REMOVE_BOARD_ADMIN_ACTION = exports.ADD_BOARD_ADMIN_ACTION = exports.TOGGLE_BOARD_STATUS_ACTION = exports.ADMIN_RECEIPT_SHOW_PAGE = exports.RECEIPT_SHOW_PAGE = exports.PAYMENT_ACTION = exports.PAYMENT_FORM_PAGE = exports.RECEIPT_INDEX = exports.LOGOUT_ACTION = exports.LOGIN_ACTION = exports.LOGIN_PAGE = exports.ITEM_INDEX_PAGE = exports.WATCH_ACTION = exports.BID_ACTION = exports.CART_PAGE = exports.BOARD_SPECIFIED_ITEM_INDEX_PAGE = exports.BOARD_SPECIFIED_ITEM_SHOW_PAGE = exports.PAYMENT_NOTIFICATION_ACTION = exports.CREATE_BOARD_ACTION = exports.BOARD_CREATION_PAGE = exports.BOARD_INDEX = exports.UPDATE_BOARD_ACTION = exports.MANUAL_PAYMENT_ACTION = exports.CREATE_ITEM_ACTION = exports.ITEM_ARCHIVE_PAGE = exports.ADMIN_ITEM_INDEX_PAGE = exports.UPDATE_ITEM_ACTION = exports.UPDATE_SHIPMENT_ACTION = exports.ITEM_SHIPMENT_PAGE = exports.ITEM_CREATION_PAGE = exports.EDIT_ITEM_PAGE = exports.DELETE_HIGHEST_BID_ACTION = exports.ARCHIVE_ITEM_ACTION = exports.EDIT_BOARD_PAGE = void 0;
var EDIT_BOARD_PAGE = "admin.board.edit";
exports.EDIT_BOARD_PAGE = EDIT_BOARD_PAGE;
var ARCHIVE_ITEM_ACTION = "admin.board.item.archive";
exports.ARCHIVE_ITEM_ACTION = ARCHIVE_ITEM_ACTION;
var DELETE_HIGHEST_BID_ACTION = "admin.board.item.bid.destroy";
exports.DELETE_HIGHEST_BID_ACTION = DELETE_HIGHEST_BID_ACTION;
var EDIT_ITEM_PAGE = "admin.board.item.edit";
exports.EDIT_ITEM_PAGE = EDIT_ITEM_PAGE;
var ITEM_SHIPMENT_PAGE = "admin.board.item.shipment.edit";
exports.ITEM_SHIPMENT_PAGE = ITEM_SHIPMENT_PAGE;
var UPDATE_SHIPMENT_ACTION = "admin.board.item.shipment.update";
exports.UPDATE_SHIPMENT_ACTION = UPDATE_SHIPMENT_ACTION;
var UPDATE_ITEM_ACTION = "admin.board.item.update";
exports.UPDATE_ITEM_ACTION = UPDATE_ITEM_ACTION;
var ADMIN_ITEM_INDEX_PAGE = "admin.board.item.index";
exports.ADMIN_ITEM_INDEX_PAGE = ADMIN_ITEM_INDEX_PAGE;
var ITEM_ARCHIVE_PAGE = "admin.board.item.archive.index";
exports.ITEM_ARCHIVE_PAGE = ITEM_ARCHIVE_PAGE;
var ITEM_CREATION_PAGE = "admin.board.item.create";
exports.ITEM_CREATION_PAGE = ITEM_CREATION_PAGE;
var CREATE_ITEM_ACTION = "admin.board.item.store";
exports.CREATE_ITEM_ACTION = CREATE_ITEM_ACTION;
var MANUAL_PAYMENT_ACTION = "admin.board.payment.store";
exports.MANUAL_PAYMENT_ACTION = MANUAL_PAYMENT_ACTION;
var UPDATE_BOARD_ACTION = "admin.board.update";
exports.UPDATE_BOARD_ACTION = UPDATE_BOARD_ACTION;
var BOARD_INDEX = "admin.board.index";
exports.BOARD_INDEX = BOARD_INDEX;
var BOARD_CREATION_PAGE = "admin.board.create";
exports.BOARD_CREATION_PAGE = BOARD_CREATION_PAGE;
var CREATE_BOARD_ACTION = "admin.board.store";
exports.CREATE_BOARD_ACTION = CREATE_BOARD_ACTION;
var PAYMENT_NOTIFICATION_ACTION = "admin.notify.payment";
exports.PAYMENT_NOTIFICATION_ACTION = PAYMENT_NOTIFICATION_ACTION;
var BOARD_SPECIFIED_ITEM_SHOW_PAGE = "board.item.show";
exports.BOARD_SPECIFIED_ITEM_SHOW_PAGE = BOARD_SPECIFIED_ITEM_SHOW_PAGE;
var BOARD_SPECIFIED_ITEM_INDEX_PAGE = "board.item.index";
exports.BOARD_SPECIFIED_ITEM_INDEX_PAGE = BOARD_SPECIFIED_ITEM_INDEX_PAGE;
var CART_PAGE = "payment.create";
exports.CART_PAGE = CART_PAGE;
var BID_ACTION = "item.bid.store";
exports.BID_ACTION = BID_ACTION;
var WATCH_ACTION = "item.watchlist.toggle";
exports.WATCH_ACTION = WATCH_ACTION;
var ITEM_INDEX_PAGE = "item.index";
exports.ITEM_INDEX_PAGE = ITEM_INDEX_PAGE;
var LOGIN_PAGE = "auth.login";
exports.LOGIN_PAGE = LOGIN_PAGE;
var LOGIN_ACTION = "auth.login";
exports.LOGIN_ACTION = LOGIN_ACTION;
var LOGOUT_ACTION = "auth.logout";
exports.LOGOUT_ACTION = LOGOUT_ACTION;
var RECEIPT_INDEX = "receipt.index";
exports.RECEIPT_INDEX = RECEIPT_INDEX;
var PAYMENT_FORM_PAGE = "payment.create";
exports.PAYMENT_FORM_PAGE = PAYMENT_FORM_PAGE;
var PAYMENT_ACTION = "payment.store";
exports.PAYMENT_ACTION = PAYMENT_ACTION;
var RECEIPT_SHOW_PAGE = "receipt.show";
exports.RECEIPT_SHOW_PAGE = RECEIPT_SHOW_PAGE;
var ADMIN_RECEIPT_SHOW_PAGE = 'admin.board.item.receipt.show';
exports.ADMIN_RECEIPT_SHOW_PAGE = ADMIN_RECEIPT_SHOW_PAGE;
var TOGGLE_BOARD_STATUS_ACTION = 'admin.board.toggle-status';
exports.TOGGLE_BOARD_STATUS_ACTION = TOGGLE_BOARD_STATUS_ACTION;
var ADD_BOARD_ADMIN_ACTION = 'admin.board.edit.user.add';
exports.ADD_BOARD_ADMIN_ACTION = ADD_BOARD_ADMIN_ACTION;
var REMOVE_BOARD_ADMIN_ACTION = 'admin.board.edit.user.remove';
exports.REMOVE_BOARD_ADMIN_ACTION = REMOVE_BOARD_ADMIN_ACTION;
var CHANGE_BOARD_ADMIN_ROLE_ACTION = 'admin.board.edit.user.changeRole';
exports.CHANGE_BOARD_ADMIN_ROLE_ACTION = CHANGE_BOARD_ADMIN_ROLE_ACTION;
