<template>
    <div v-if="editor">
        <v-row class="mt-3">
            <v-col>
                <label v-if="label">{{ label }}</label>
                <div class="tw-border tw-border-b-0 tw-border-gray-400 tw-border-solid">
                    <v-btn
                        v-for="(btn, i) in buttons"
                        :key="i"
                        :color="
          btn.name && editor.isActive(btn.name, { level: btn.level })
            ? 'blue'
            : 'black'
        "
                        icon
                        @click="btn.method"
                    >
                        <v-icon>{{ btn.icon }}</v-icon>
                    </v-btn
                    >
                </div>
            </v-col>
        </v-row>
        <editor-content :editor="editor"/>
    </div>
</template>

<script>
import {Editor, EditorContent} from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";

export default {
    components: {
        EditorContent,
    },
    
    computed: {
        buttons() {
            return [
                {
                    icon: "mdi-format-bold",
                    method: () => this.editor.chain().focus().toggleBold().run(),
                    name: "bold",
                },
                {
                    icon: "mdi-format-italic",
                    method: () => this.editor.chain().focus().toggleItalic().run(),
                    name: "italic",
                },
                {
                    icon: "mdi-format-underline",
                    method: () => this.editor.chain().focus().toggleUnderline().run(),
                    name: "underline",
                },
                {
                    icon: "mdi-format-strikethrough",
                    method: () => this.editor.chain().focus().toggleStrike().run(),
                    name: "strike",
                },
                {
                    icon: "mdi-xml",
                    method: () => this.editor.chain().focus().toggleCode().run(),
                    name: "code",
                },
                {
                    icon: "mdi-format-clear",
                    method: () => this.editor.chain().focus().clearNodes().run(),
                    name: false,
                },
                {
                    icon: "mdi-format-paragraph",
                    method: () => this.editor.chain().focus().setParagraph().run(),
                    name: "paragraph",
                },
                {
                    icon: "mdi-format-header-1",
                    method: () =>
                        this.editor.chain().focus().toggleHeading({level: 1}).run(),
                    name: "heading",
                    level: 1,
                },
                {
                    icon: "mdi-format-header-2",
                    method: () =>
                        this.editor.chain().focus().toggleHeading({level: 2}).run(),
                    name: "heading",
                    level: 2,
                },
                {
                    icon: "mdi-format-header-3",
                    method: () =>
                        this.editor.chain().focus().toggleHeading({level: 3}).run(),
                    name: "heading",
                    level: 3,
                },
                {
                    icon: "mdi-format-list-bulleted",
                    method: () => this.editor.chain().focus().toggleBulletList().run(),
                    name: "bulletList",
                },
                {
                    icon: "mdi-format-list-numbered",
                    method: () => this.editor.chain().focus().toggleOrderedList().run(),
                    name: "orderedList",
                },
                {
                    icon: "mdi-format-quote-open",
                    method: () => this.editor.chain().focus().toggleBlockquote().run(),
                    name: "blockquote",
                },
                {
                    icon: "mdi-minus",
                    method: () => this.editor.chain().focus().setHorizontalRule().run(),
                    name: false,
                },
                {
                    icon: "mdi-undo",
                    method: () => this.editor.chain().focus().undo().run(),
                    name: false,
                },
                {
                    icon: "mdi-redo",
                    method: () => this.editor.chain().focus().redo().run(),
                    name: false,
                },
            ];
        },
    },
    
    props: {
        value: {
            type: String,
            default: "",
        },
        label: String,
    },
    
    data() {
        return {
            editor: null,
            toggleButtons: [],
        };
    },
    
    watch: {
        value(value) {
            // HTML
            const isSame = this.editor.getHTML() === value;
            
            // JSON
            // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)
            
            if (isSame) {
                return;
            }
            
            this.editor.commands.setContent(value, false);
        },
    },
    
    mounted() {
        this.editor = new Editor({
            content: this.value,
            extensions: [StarterKit, Underline],
            onUpdate: () => {
                // HTML
                this.$emit("input", this.editor.getHTML());
                
                // JSON
                // this.$emit('input', this.editor.getJSON())
            },
        });
    },
    
    beforeDestroy() {
        this.editor.destroy();
    },
};
</script>

<style>
.ProseMirror {
    border: 1px solid rgb(156, 163, 175);
    height: 500px;
    overflow-y: scroll;
    padding: 20px;
}
</style>
