var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('InertiaHead',{attrs:{"title":"Shipment"}}),_vm._v(" "),_c('Main',[_c('BaseForm',{ref:"form",attrs:{"form":_vm.form}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',[_c('v-card',{staticClass:"mt-4"},[_c('v-card-text',[(_vm.shippingAddress.length > 0)?_c('div',{staticClass:"text-center"},[_c('h3',[_vm._v("Shipping Address")]),_vm._v(" "),_c('p',{domProps:{"innerHTML":_vm._s(_vm.shippingAddress)}})]):_c('p',[_vm._v("No Shipping Address Given")]),_vm._v(" "),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('vc-date-picker',{attrs:{"disabled":!_vm.enabled,"mode":"date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var togglePopover = ref.togglePopover;
return [_c('v-text-field',{attrs:{"disabled":!_vm.enabled,"value":inputValue,"label":"Shipment Date","readonly":"","error-messages":_vm.errors.shipmentDate},on:{"click":togglePopover}})]}}]),model:{value:(_vm.form.shipmentDate),callback:function ($$v) {_vm.$set(_vm.form, "shipmentDate", $$v)},expression:"form.shipmentDate"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('vc-date-picker',{attrs:{"disabled":!_vm.enabled,"mode":"date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var togglePopover = ref.togglePopover;
return [_c('v-text-field',{attrs:{"disabled":!_vm.enabled,"value":inputValue,"readonly":"","label":"Est. Delivery Date","error-messages":_vm.errors.estDeliveryDate},on:{"click":togglePopover}})]}}]),model:{value:(_vm.form.estDeliveryDate),callback:function ($$v) {_vm.$set(_vm.form, "estDeliveryDate", $$v)},expression:"form.estDeliveryDate"}})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[(_vm.enabled)?_c('v-autocomplete',{attrs:{"error-messages":_vm.errors.courier,"items":_vm.options,"search-input":_vm.search,"item-text":"name","item-value":"value","label":"Courier"},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                                                "+_vm._s(item.name)+"\n                                            ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                                                "+_vm._s(item.name)+"\n                                            ")]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"my-4 ml-4"},[_vm._v("\n                                                    Courier not in list?\n                                                    "),_c('v-btn',{attrs:{"icon":"","text":""},on:{"click":_vm.add}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)]},proxy:true}],null,false,2028218361),model:{value:(_vm.form.courier),callback:function ($$v) {_vm.$set(_vm.form, "courier", $$v)},expression:"form.courier"}}):_c('v-text-field',{staticClass:"mt-2",attrs:{"disabled":"","label":"Courier","required":""},model:{value:(_vm.form.courier),callback:function ($$v) {_vm.$set(_vm.form, "courier", $$v)},expression:"form.courier"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[(_vm.showTrackingNumber)?_c('v-text-field',{staticClass:"mt-2",attrs:{"disabled":!_vm.enabled,"error-messages":_vm.errors.trackingNumber,"label":"Tracking Number","required":""},model:{value:(_vm.form.trackingNumber),callback:function ($$v) {_vm.$set(_vm.form, "trackingNumber", $$v)},expression:"form.trackingNumber"}}):_vm._e()],1)],1)],1)],1),_vm._v(" "),_c('v-card-actions',{staticClass:"tw-justify-center sm:tw-justify-start"},[(_vm.enabled)?_c('v-btn',{staticClass:"ml-5",attrs:{"loading":_vm.processing,"color":"primary","type":"button","disabled":!_vm.enableSubmit},on:{"click":_vm.send}},[_vm._v("\n                                Submit\n                            ")]):_vm._e()],1),_vm._v(" "),_c('v-card-actions',{staticClass:"tw-text-center sm:tw-justify-start"},[_c('div',[_c('strong',[_vm._v("WARNING")]),_vm._v(": Clicking submit will\n                                send a notification to\n                                the winner.\n                            ")])])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }