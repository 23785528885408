<template>
    <v-row
        v-if="currentUserIsWinner && isWonButNotPaid"
        class="my-4"
        justify="center"
    >
        <v-col>
            <v-btn v-if="bankIsActive" color="success" @click="$inertia.get(cartPaymentCreateRoute)">
                <v-icon left>mdi-star</v-icon>
                Click to Pay Now
            </v-btn>
            <v-btn v-else color="success" disabled>
                <v-icon left>mdi-star</v-icon>
                Click to Pay Now
            </v-btn>
        </v-col>
    </v-row>
</template>
<script>
import _route from "@/Modules/Misc/route.ts";

function cartPaymentCreateRoute() {
    return _route('payment.create');
}

const props = {
    currentUserIsWinner: {type: Boolean, required: true},
    isWonButNotPaid: {type: Boolean, required: true},
    bankIsActive: {
        type: Boolean,
        required: true
    }
    
}

export default {
    props,
    computed: {
        cartPaymentCreateRoute
    }
}
</script>
