var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.item.bid_count > 0)?_c('v-dialog',{attrs:{"width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(
                _vm.permissions.delete_bids &&
                (_vm.itemIsOpenToBid ||
                    _vm.itemIsEndedWithoutSell)
            )?_c('v-btn',{class:_vm.buttonClass,attrs:{"small":"","text":""},on:{"click":function($event){_vm.dialog = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v("\n            Delete Highest Bid\n        ")],1):_vm._e()]}}],null,false,1997512893),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_vm._v(" "),_c('v-card',[_c('v-card-title',[_vm._v("Delete Highest Bid for "+_vm._s(_vm.item.truncated_name)+"\n        ")]),_vm._v(" "),_c('v-card-text',[_c('p',[_vm._v("This action cannot be undone!")])]),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Cancel")]),_vm._v(" "),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"error"},on:{"click":_vm.handleSubmit}},[_vm._v("Delete\n            ")])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }