import {Head, Link} from "@inertiajs/inertia-vue";
import BaseForm from "@/Views/Components/Misc/BaseForm.vue";
import Breadcrumbs from "@/Views/Components/Misc/Breadcrumbs.vue";
import Vue from "vue";

Vue.component("InertiaHead", Head);
Vue.component("Head", Head);
Vue.component("InertiaLink", Link);
Vue.component("Link", Link)

Vue.component("BaseForm", BaseForm);
Vue.component("Breadcrumbs", Breadcrumbs);
